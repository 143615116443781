import mixpanelPlugin from '@analytics/mixpanel';
import Analytics from 'analytics';

import debugPlugin from './debugPlugin';
import { TEMPLATE_EVENTS } from './events';
import { params } from './utils';

const plugins = [];
if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
  plugins.push(
    mixpanelPlugin({
      token: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
      pageEvent: 'pageView',
      options: {
        ignore_dnt: true,
      },
    })
  );
}

if (params.debugAnalytics) {
  plugins.push(debugPlugin());
}

const analytics = Analytics({
  app: 'kittl',
  debug: params.debugAnalytics,
  plugins: [...plugins],
});

// Defaults for all events
analytics.on('identifyStart', ({ payload }) => {
  payload.options.plugins = {
    ...payload.options.plugins,
    debug: params.debugAnalytics,
  };
});

analytics.on('pageStart', ({ payload }) => {
  payload.options = {
    ...payload.options,
    plugins: {
      mixpanel: true,
      debug: params.debugAnalytics,
      ...payload.options.plugins,
    },
  };
});

analytics.on('trackStart', ({ payload }) => {
  payload.options = {
    ...payload.options,
    plugins: {
      mixpanel: true,
      debug: params.debugAnalytics,
      ...payload.options.plugins,
    },
  };
});

export default analytics;
export { TEMPLATE_EVENTS };
