import React, { useEffect } from 'react';

import { AppProps } from 'next/app';
import Head from 'next/head';

import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';

import analytics from '@repo/analytics';
import './global.css';
import { initMonitoring } from 'utils/rum';

const GROWTHBOOK_SDK_API_KEY =
  process.env.GROWTHBOOK_SDK_API_KEY ||
  process.env.NEXT_PUBLIC_GROWTHBOOK_SDK_API_KEY;

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_SDK_API_KEY,
  enableDevMode: process.env.NODE_ENV === 'development',
  subscribeToChanges: true,
});

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  useEffect(() => {
    initMonitoring();
    growthbook.loadFeatures();

    // Identify the user if they have not been identified yet
    if (pageProps.account?.id && !analytics.user().userId) {
      const { id, email, name } = pageProps.account;
      analytics.identify(id, {
        email,
        name,
        id,
      });
    }
  }, [pageProps.account]);

  return (
    <>
      <Head>
        <title>Kittl - CMS</title>
      </Head>
      <GrowthBookProvider growthbook={growthbook}>
        <Component {...pageProps} />
      </GrowthBookProvider>
    </>
  );
}

export default MyApp;
