import { AnalyticsData } from './types';

/* eslint-disable no-console */
export default function debugPlugin() {
  return {
    name: 'debug',
    initialize: ({ payload }: AnalyticsData) => {
      // Check if window is undefined to avoid build and SSR errors.
      if (typeof window !== 'undefined') {
        console.log('[analytics] initialize', payload);
      }
    },
    page: ({ payload }: AnalyticsData) => {
      console.log('[analytics] page', { ...payload });
    },
    track: ({ payload }: AnalyticsData) => {
      console.log('[analytics] track', { ...payload });
    },
    identify: ({ payload }: AnalyticsData) => {
      console.log('[analytics] identify', { ...payload });
    },
    reset: () => {
      console.log('[analytics] reset');
    },
  };
}
