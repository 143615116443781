// @TODO: Move to external package
const urlParams = new URLSearchParams(
  typeof window === 'undefined' ? undefined : window?.location?.search
);

const DEBUG_ANALYTICS_QUERY_PARAM = '__debug_analytics';
const debugAnalytics = Boolean(urlParams.get(DEBUG_ANALYTICS_QUERY_PARAM));

export const params = {
  debugAnalytics,
};
